import { render, staticRenderFns } from "./PalletReceipts.vue?vue&type=template&id=3b9b3e8e"
import script from "./PalletReceipts.vue?vue&type=script&lang=js"
export * from "./PalletReceipts.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports